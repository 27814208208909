<!-- 
	文件空间：所有人都可以访问的一个空间 
 -->
<template>
	<div class="file_herderall">
		<el-dialog title="文件空间" :fullscreen="dialogFull" :center="true" :show-close="false" :destroy-on-close="false"
			@close="closedialog" :visible.sync="showfilevisible" width="60%">
			<div style="background-color: #f7f7f7;padding: 5px;display: flex;height: 100%;" v-loading="loadingleft">
				<el-card class="box-card" shadow="never">
					<span v-for="(item,index) in list_terminal_groupdata" :key="index">
						<div :class="item.name == selectfilename ? 'card_body_aot' : 'card_body_aotwe'"
							@contextmenu.prevent="openMenu($event,item)" @click="selectfilegro(item)">
							<div>{{item.name}}</div>
							<div>
								<i class="el-icon-arrow-right"></i>
							</div>
						</div>
					</span>
					<div v-show="contextcatalogueshow" :style="{ left: left + 'px', top: top + 'px' }"
						class="contextmenu">
						<!-- <div @click="preview_file">预览</div> -->
						<!-- <div @click="onCopy">复制地址</div> -->
						<div @click="rechristen">重命名</div>
						<!-- <div @click="move_file">移动文件</div> -->
						<!-- <div>选中</div> -->
						<div @click="delete_files_interspace">删除</div>
					</div>
				</el-card>
				<el-card class="box-card2" shadow="never" v-loading="loadingright" :element-loading-text="loadingupnum">
					<!-- 新增按钮 -->
					<el-row :gutter="10">
						<el-col :span="1.5">
							<el-button type="warning" plain icon="el-icon-refresh-left" size="mini" @click="handshua">刷新
							</el-button>
						</el-col>
						<el-col :span="1.5">
							<el-upload :data="headerObj" :on-success="onsuccessfile" :on-progress="onprogressis"
								:action="host_name+'/admin/device_resource/up_files_resoce'" :on-preview="handlePreview"
								:on-remove="handleRemove" :before-remove="beforeRemove" multiple
								:on-exceed="handleExceed">
								<!-- :file-list="fileList" -->
								<el-button type="primary" plain icon="el-icon-upload" size="mini"
									@click="handlefileAdd">上传</el-button>
							</el-upload>
						</el-col>
						<el-col :span="1.5">
							<el-button type="success" plain icon="el-icon-document-checked" size="mini"
								@click="handleselect">使用选中文件{{selectlistitemlen}}/{{maxfilenum}}</el-button>
						</el-col>
						<el-col :span="1.5">
							<el-button type="primary" plain icon="el-icon-folder-add" size="mini" @click="add_folder">
								新建文件夹</el-button>
						</el-col>
						<el-col :span="1.5">
							<el-button type="danger" plain icon="el-icon-delete" size="mini" @click="deletefile">删除选中文件
							</el-button>
						</el-col>
					</el-row>

					<div style="height: 500px;overflow: scroll; position: relative;">
						<div class="file_he">
							<span v-for="(item,index) in file_interspaceinfo" @click="selecttopath(item)">
								<div class="file_he_one" @contextmenu.prevent="openMenu($event,item)">
									<div class="el-tag_nei">
										<el-tag style="z-index: 999;" size="mini" type="success">{{item.typename}}
										</el-tag>
									</div>
									<span v-for="(item1,index1) in selectlistitem">
										<div class="el-tag_nei">
											<el-tag style="z-index: 999;" size="mini" type="success">{{item.typename}}
											</el-tag>
											<el-badge v-if="item1.full_path ==  item.full_path" :value="index1+1"
												type="success"
												style="width: 18px;height: 18px;margin: 5px;z-index: 99999;">
											</el-badge>
										</div>
										<div v-if="item1.full_path ==  item.full_path"
											style="position: absolute;width: 120px;height: 120px;	background:rgba(30, 30, 30, 0.4);z-index: 9999;border-radius: 7px;">
										</div>
									</span>
									<div class="file_bac_im">
										<el-image style="width: 120px; height: 120px;border-radius: 7px;"
											:src="item.showimage" fit="contain"></el-image>
									</div>
								</div>
								<div class="file_name_text">
									{{item.name}}
								</div>
							</span>

							<div v-show="contextmenushow" :style="{ left: left + 'px', top: top + 'px' }"
								class="contextmenu">
								<div @click="preview_file">预览</div>
								<div @click="onCopy">复制地址</div>
								<div @click="rechristen">重命名</div>
								<div @click="move_file">移动文件</div>
								<!-- 							<div>选中</div>
							<div @click="delete_files_interspace">删除</div> -->
							</div>
						</div>

					</div>



				</el-card>
			</div>
			<template slot="title">
				<div class="avue-crud__dialog__header">
					<!-- <div>123</div> -->
					<span class="el-dialog__title">
						<span
							style="display:inline-block;background-color: #3478f5;width:3px;height:20px;margin-right:5px; float: left;margin-top:2px"></span>
						文件空间
					</span>
					<div>
						<div class="avue-crud__dialog__menu" @click="dialogFull? dialogFull=false: dialogFull=true">
							<i class="el-icon-full-screen"></i>
						</div>
						<div class="avue-crud__dialog__menu" @click="closedialog">
							<i class="el-icon-close"></i>
						</div>
					</div>
				</div>
			</template>
		</el-dialog>
		<el-dialog v-if="dialogFormVisibletaskname" title="创建文件夹" :visible.sync="dialogFormVisibletaskname" width="50%">
			<el-form ref="newdeviceinfo" :model="newfileinfo">
				<el-form-item :label-width="formLabelWidth" prop="filename">
					<span slot="label">
						<span style="color: black;">文件夹名:</span>
					</span>
					<el-input v-model="newfileinfo.filename" style="width: 500px;" placeholder="文件夹名" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<!-- <el-button type="danger" @click="confirmtasknamequ">取消</el-button> -->
				<el-button type="primary" @click="confirmtaskname">确定</el-button>
			</div>
		</el-dialog>

		<el-dialog v-if="dialogFormVisibletasfilename" title="重命名" :visible.sync="dialogFormVisibletasfilename"
			width="50%">
			<el-form ref="newdeviceinfo" :model="rightclickda">
				<el-form-item :label-width="formLabelWidth" prop="filename">
					<span slot="label">
						<span style="color: black;">文件名:</span>
					</span>
					<el-input v-model="rightclickda.name" style="width: 500px;" placeholder="文件名" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<!-- <el-button type="danger" @click="confirmtasknamequ">取消</el-button> -->
				<el-button type="primary" @click="rename_filefun">确定</el-button>
			</div>
		</el-dialog>

		<!-- 修改左侧的信息 -->
		<el-dialog title="修改" :visible.sync="dialogFormVisiblemodification" width="50%">
			<el-form ref="rightclickda" :model="rightclickda">
				<!-- 选择的列表信息 | 设备才选择 -->
				<el-form-item :label-width="formLabelWidth">
					<span slot="label">
						<span style="color: black;">任务分组：</span>
					</span>
					<!-- {{selectfileinfo}} -->
					<el-select v-model="selectfileinfo.path" style="width: 180px" clearable>
						<el-option v-for="item in list_terminal_groupdata" :key="item.path" :label="item.name"
							:value="item.path" />
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<!-- <el-button type="danger" @click="cancel_modification">取消</el-button> -->
				<el-button type="primary" @click="confirm_modification">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	// 系统用户相关的
	const {
		file_interspace,
		list_file_group,
		get_group_resoce,
		delete_resoce,
		delete_files_interspacereq,
		creat_file_interspace, //添加文件夹
		rename_file, //重命名文件
		move_file, //移动文件
		get_locl_ip, //获取自己的ip 的信息
	} = require("@/api/materialad");
	export default {
		name: 'fileinterspace',
		props: {
			// 是否显示文件空间
			showfilevisible: {
				type: Boolean,
				default: false
			},
			maxfilenum: {
				type: Number,
				default: 9
			},
			identifying: {
				default: 1
			}
		},
		// watch 监听函数
		watch: {
			// 监听是否打开了菜单栏
			contextmenushow(value) {
				// 如果打开了菜单栏
				if (value) {
					// 在body上设置点击事件 ， 移除菜单栏
					document.body.addEventListener('click', this.closeMenu)
				} else {
					// 如果没有出现菜单栏 ， 就直接移除body上的点击事件，以免其他页面出现问题
					document.body.removeEventListener('click', this.closeMenu)
				}
			},
			// 监听是否打开了菜单栏
			contextcatalogueshow(value) {
				// 如果打开了菜单栏
				if (value) {
					// 在body上设置点击事件 ， 移除菜单栏
					document.body.addEventListener('click', this.closeMenu)
				} else {
					// 如果没有出现菜单栏 ， 就直接移除body上的点击事件，以免其他页面出现问题
					document.body.removeEventListener('click', this.closeMenu)
				}
			}
		},
		data() {
			return {
				loadingupnum: '加载中',
				dialogFormVisiblemodification: false,
				// 是否关闭弹窗
				dialogFormVisibletasfilename: false,
				// 上传的文件名
				newfileinfo: {
					filename: ''
				},
				// 关闭和打开的按钮
				dialogFormVisibletaskname: false,
				formLabelWidth: '110px',
				// 这个是文件夹的位置
				newpath: '',
				loadingright: false,
				// 显示的文件夹名
				selectfilename: "",
				// 显示文件夹的信息
				selectfileinfo: {},
				list_terminal_groupdata: [],
				loadingleft: true,
				headerObj: {
					token: window.localStorage.getItem("token"),
					//现在的位置
					nowpath: ""
				},
				//选中的数据
				selectlistitem: [],
				// 右击的数据
				rightclickda: {},
				// 右侧内容区
				contextmenushow: false,
				// 左侧导航栏
				contextcatalogueshow: false,
				left: 0,
				top: 0,
				// 拿去请求的路径
				indexpath: null,
				// 加载
				loading: true,
				// 框的放大缩小
				dialogFull: false,
				// 文件列表信息
				file_interspaceinfo: [],
				// 现在可以访问的ip地址
				host_name: ''
			};
		},
		created() {
			this.get_locl_ipfun()
			// this.getfile_interspace();
			// 分组列表信息
			this.list_terminal_groupfun()
		},
		computed: {
			selectlistitemlen() {
				return this.selectlistitem.length;
			}
		},
		methods: {
			// 获取自己的ip信息供上传
			async get_locl_ipfun() {
				let get_locl_ipres = await get_locl_ip();
				this.host_name = get_locl_ipres.data.host_name
			},

			onprogressis(e) {
				this.loadingright = true;
				this.loadingupnum = '已上传' + e.percent.toFixed(2) + '%'
				// console.log("数据是：",e)
			},
			// 点击确定
			async confirm_modification() {
				let move_fileres = await move_file({
					oldpath: this.rightclickda.path,
					newpath: this.selectfileinfo.path,
				});
				if (move_fileres.code == 1) {
					this.$message.success("分组成功~");
					this.list_terminal_groupfun()
					this.move_file();
					this.rightclickda = {}
				}
			},

			//移动文件
			move_file() {
				this.dialogFormVisiblemodification = !this.dialogFormVisiblemodification
			},

			// 重命名这个文件
			async rename_filefun() {
				let rename_fileres = await rename_file({
					oldpath: this.rightclickda.path,
					newname: this.rightclickda.name
				})
				if (rename_fileres.code == 1) {
					this.$message.success("创建成功~");
					this.list_terminal_groupfun()
					this.rechristen();

					this.rightclickda = {}
				}
				console.log(123456, rename_fileres)

			},
			// 确定
			async confirmtaskname() {
				let creat_file_interspaceres = await creat_file_interspace({
					nowpath: this.initialfileindex,
					...this.newfileinfo
				})
				if (creat_file_interspaceres.code == 1) {
					this.$message.success("创建成功~");
					this.list_terminal_groupfun()
					this.add_folder();
					this.newfileinfo.filename = ''
				}
			},
			// 新建文件夹
			add_folder() {
				this.dialogFormVisibletaskname = !this.dialogFormVisibletaskname;
			},
			// 重命名这个文件 | 文件夹
			rechristen() {
				this.dialogFormVisibletasfilename = !this.dialogFormVisibletasfilename

			},

			// 删除对应的
			async delete_resocefun(delebody) {
				let delete_resoceres = await delete_resoce({
					delebody
				})
				if (delete_resoceres.code == 1) {
					this.$message.success("删除成功~")
				}
				let list_terminal_groupres = await list_file_group();
				this.list_terminal_groupdata = list_terminal_groupres.data.data;
				this.get_group_resocefun(this.indexpath);

				this.selectlistitem = []
			},
			async get_group_resocefun(path) {
				this.loadingright = true;
				let get_group_resoceres = await get_group_resoce({
					path
				});
				this.file_interspaceinfo = get_group_resoceres.data.data;
				this.indexpath = get_group_resoceres.data.indexpath;
				this.headerObj.nowpath = get_group_resoceres.data.indexpath;
				this.loadingright = false;
			},
			// 点击左侧的文件夹。获取对应的文件信息
			selectfilegro(item) {
				this.selectfilename = item.name;
				this.selectfileinfo = JSON.parse(JSON.stringify(item));
				this.get_group_resocefun(item.path)
			},
			// 获取文件列表组
			async list_terminal_groupfun() {
				this.loadingleft = true;
				let list_terminal_groupres = await list_file_group();
				this.list_terminal_groupdata = list_terminal_groupres.data.data;
				this.selectfilename = this.list_terminal_groupdata.length ? this.list_terminal_groupdata[0].name : '';
				this.selectfileinfo = this.list_terminal_groupdata.length ? JSON.parse(JSON.stringify(this
					.list_terminal_groupdata[0])) : {};
				this.list_terminal_groupdata.length ? this.get_group_resocefun(this.list_terminal_groupdata[0].path) :
					'';
				this.indexpath = list_terminal_groupres.data.indexpath;
				// 获取初始的列表信息
				this.initialfileindex = list_terminal_groupres.data.indexpath;
				this.loadingleft = false;
			},

			// 上传成功了
			onsuccessfile() {
				this.get_group_resocefun(this.indexpath)
				this.loadingright = false;
				this.loadingupnum = '加载中';
				this.$message.success("上传成功~")
			},

			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePreview(file) {
				console.log(file);
			},
			handleExceed(files, fileList) {
				this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
			},
			beforeRemove(file, fileList) {
				return this.$confirm(`确定移除 ${ file.name }？`);
			},
			// 文件的
			// 复制
			onCopy() {
				var input = document.createElement("input"); // 创建input对象
				input.value = this.rightclickda.full_path; // 设置复制内容
				document.body.appendChild(input); // 添加临时实例
				input.select(); // 选择实例内容
				document.execCommand("Copy"); // 执行复制
				document.body.removeChild(input); // 删除临时实例
				this.$message.success("复制成功~")
			},
			preview_file() {
				if (this.rightclickda.isfile) {
					window.open(this.rightclickda.full_path, "_blank");
					return;
				}
				this.get_group_resocefun(this.rightclickda.path)
			},

			// 关闭菜单
			closeMenu() {
				console.log("关闭了")
				this.contextmenushow = false;
				this.contextcatalogueshow = false;
			},
			// 鼠标右击
			openMenu(e, item) {
				this.rightclickda = item
				// 设置菜单栏出现的位置
				this.top = e.clientY;
				this.left = e.clientX;
				// 如果是文件的话
				if (item.isfile) {
					this.contextmenushow = true;
				} else {
					this.contextcatalogueshow = true;
				}
				// 右击打开了菜单栏
			},
			// 关闭弹窗事件
			closedialog() {
				this.$emit("closedialog", null)
			},
			//刷新按钮
			handshua() {
				this.get_group_resocefun(this.indexpath)
			},
			// 文件上传
			handlefileAdd() {

			},
			// 获取选中的文件信息使用
			handleselect(item) {
				this.$emit("selectfileArr", {
					id: this.identifying,
					selectlistitem: this.selectlistitem
				});
				this.closedialog()
			},
			// 删除选中 的文件
			deletefile() {
				this.delete_resocefun(JSON.stringify(this.selectlistitem))

			},
			// 点击了 文件或者文件夹
			selecttopath(item) {
				if (!item.isfile) { //说明是文件夹，就把路径给请求重新去拿数据
					this.selectlistitem = []
					this.get_group_resocefun(item.path)
					return;
				}
				let datas = JSON.parse(JSON.stringify(this.selectlistitem))

				let startds = false
				datas.forEach((items, index) => {
					if (items.full_path == item.full_path && !startds) {
						startds = true;
						datas.splice(index, 1);
						this.selectlistitem = datas;
					}
				})
				if (datas.length == this.maxfilenum) {
					this.selectlistitem.splice(0, 1);
					this.selectlistitem.push(item);
					return;
				}
				if (!startds) this.selectlistitem.push(item);
			},
			// 获取文件列表的信息
			async getfile_interspace(newpath) {
				this.loading = true;
				let file_interspaceres = await file_interspace({
					newpath
				});
				this.file_interspaceinfo = file_interspaceres.data.data;
				this.indexpath = file_interspaceres.data.indexpath;
				this.headerObj.nowpath = file_interspaceres.data.indexpath;
				this.loading = false;
			},
			// 删除目录|文件
			async delete_files_interspace(delpath) {
				this.loading = true;
				await this.delete_resocefun(JSON.stringify([this.rightclickda]));
				// let file_interspaceres = await delete_files_interspacereq({
				// 	delpath: this.rightclickda.path,
				// 	isfile: this.rightclickda.isfile
				// });

				this.loading = false;
			}


		}
	}
</script>

<style scoped lang="less">
	.card_body_aot {
		background-color: #4165d7;
		color: #fff;
		width: 90%;
		height: 35px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 10px;
		border-radius: 3px;
		margin: 6px 0;
		cursor: pointer;
	}

	.card_body_aotwe {
		color: #000;
		background-color: #ffffff;
		width: 90%;
		height: 35px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 10px;
		border-radius: 3px;
		margin: 6px 0;
		cursor: pointer;
	}

	.file_herderall {
		background-color: #f7f7f7;
	}

	.text {
		font-size: 14px;
	}

	.item {
		margin-bottom: 18px;
	}

	.clearfix:before,
	.clearfix:after {
		display: table;
		content: "";
	}

	.clearfix:after {
		clear: both
	}

	// .one_box-card {
	// 	width: 200px;
	// 	min-height: 500px;
	// }

	.box-card {
		width: 200px;
		height: 500px;
	}

	.box-card2 {
		width: 85%;
		margin-left: 2px;
		min-height: 500px;
	}

	::v-deep .el-dialog__body {
		text-align: initial;
		padding: 3px;
		// background-color: antiquewhite;
		// .box-card{
		// 	background-color: aquamarine;
		// }
	}

	::v-deep .el-card__body {
		padding: 5px;
		// background-color: antiquewhite;
		// .box-card{
		// 	background-color: aquamarine;
		// }
	}


	//这里注意：我当前将css样式加载全局上，如果单页添加样式需要每个样式前添加 ‘/deep/' 修饰符

	/*  dialog*/
	.el-dialog__header {
		padding: 15px 20px 15px;
	}

	.el-dialog__headerbtn {
		top: 15px;
	}

	/*dialog header*/
	.el-dialog__header {
		background: #e3eaed;
	}

	.avue-crud__dialog__header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.el-dialog__title {
		color: rgba(0, 0, 0, .85);
		font-weight: 500;
		word-wrap: break-word;
	}

	.avue-crud__dialog__menu {
		padding-right: 10px;
		float: left;
	}

	.avue-crud__dialog__menu i {
		color: #909399;
		font-size: 18px;
	}

	.el-icon-full-screen {
		cursor: pointer;
	}

	.el-icon-full-screen:before {
		content: "\e719";
	}

	.file_he {
		width: 100%;
		height: 460px;
		// overflow: scroll;   // 超出内容加滚动条
		// border: 1px solid #f06;
		margin: 7px 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		.file_he_one {
			width: 120px;
			height: 120px;
			// border: 1px solid #f06;
			margin: 5px;
			background-color: #ffffff;
			border-radius: 6px;
			cursor: pointer;

			.el-tag_nei {
				width: 110px;
				// border: 1px solid #f06;
				position: absolute;
				margin: 5px;
				display: flex;
				justify-content: space-between;
			}

			.file_bac_im {
				width: 150px;
				height: 150px;
				// background-color: #ed0e0e;

				img {
					width: 150px;
					height: 150px;
				}
			}


		}

		.file_name_text {
			text-align: center;
			font-size: 14px;
			width: 120px;
			// border: 1px solid #f06;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.contextmenu {
		width: 100px;
		// height: 200px;
		// border: 1px solid #3478f5;
		position: fixed;
		background-color: #ffffff;
		z-index: 99999;

		div {
			height: 30px;
			margin: 8px;
			color: #000;
			text-align: center;
			cursor: pointer;
		}
	}
</style>